import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './orderList.css'; // Import your CSS file

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [detailsVisible, setDetailsVisible] = useState({}); // Object to keep track of visible details

  useEffect(() => {
    axios.get('https://api.manidweep.functionparks.com/api/orders')
      .then(response => {
        // Log fetched orders for debugging
        console.log("Fetched orders:", response.data);

        // Sort orders by 'created_at' in descending order
        const sortedOrders = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        
        setOrders(sortedOrders);
        setLoading(false);
      })
      .catch(error => {
        console.error("Failed to fetch orders:", error);
        setError('Failed to fetch orders');
        setLoading(false);
      });
  }, []);


  const handleDetailsClick = (orderId) => {
    setDetailsVisible(prev => ({
      ...prev,
      [orderId]: !prev[orderId] // Toggle visibility
    }));
  };

  const acceptOrder = (orderId) => {
    axios.post(`https://api.manidweep.functionparks.com/api/orders/${orderId}/accept`)
      .then(response => {
        alert('Order accepted'); // Show popup message
        console.log(response.data.message); // Optional: log response
      })
      .catch(error => {
        console.error('Error accepting order:', error);
        alert('Failed to accept order'); // Show popup message on error
      });
  };
  
  const rejectOrder = (orderId) => {
    axios.post(`https://api.manidweep.functionparks.com/api/orders/${orderId}/reject`)
      .then(response => {
        alert('Order rejected'); // Show popup message
        console.log(response.data.message); // Optional: log response
      })
      .catch(error => {
        console.error('Error rejecting order:', error);
        alert('Failed to reject order'); 
      });
  };

  return (
    <>
    <div className='admin-head'>
    <h1>Admin Panel</h1>
    </div>
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>User ID</th>
            <th>Username</th> {/* New column */}
            <th>Email</th> {/* New column */}
            <th>Phone Number</th> {/* New column */}
            <th>Dishes</th>
            <th>Order Quantity</th>
            <th>Delivery Charge</th>
            <th>Delivery Tip</th>
            <th>Total Amount</th>
            <th>Address</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="10">Loading...</td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan="10">{error}</td>
            </tr>
          ) : (
            orders.map(order => (
              <React.Fragment key={order.id}>
                <tr>
                  <td>{order.id}</td>
                  <td>{order.user_id}</td>
                  <td>{order.username}</td> {/* Display username */}
                  <td>{order.email}</td> {/* Display email */}
                  <td>{order.phone_number}</td>
                  <td>{order.dishes}</td>
                  <td>{order.order_quantity}</td>
                  <td>{order.delivery_charge}</td>
                  <td>{order.delivery_tip}</td>
                  <td>{order.total_amount}</td>
                  <td>{order.address}</td>
                  <td>{new Date(order.created_at).toLocaleString()}</td>
                  <td>
                    <div className="button-container">
                      <button className= "Action-button" onClick={() => acceptOrder(order.id)}>Accept Order</button>
                      <button className= "Action-button" onClick={() => rejectOrder(order.id)}>Reject Order</button>
                      <button className= "Action-button" onClick={() => handleDetailsClick(order.id)}>
                        {detailsVisible[order.id] ? 'Hide Details' : 'Show Details'}
                      </button>
                    </div>
                  </td>
                </tr>
                {detailsVisible[order.id] && (
                  <tr>
                    <td colSpan="10" className="details">
                      <div>
                        <h3>Order Details:</h3>
                        <p><strong>Dishes:</strong> {order.dishes}</p>
                        <p><strong>Order Quantity:</strong> {order.order_quantity}</p>
                        <p><strong>Delivery Charge:</strong> {order.delivery_charge}</p>
                        <p><strong>Delivery Tip:</strong> {order.delivery_tip}</p>
                        <p><strong>Total Amount:</strong> {order.total_amount}</p>
                        <p><strong>Address:</strong> {order.address}</p>
                        <p><strong>Created At:</strong> {new Date(order.created_at).toLocaleString()}</p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          )}
        </tbody>
      </table>
    </div>
    </>
    
  );
};

export default OrderList;
