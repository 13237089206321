import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './dashboard.css'; // Import the CSS file
import totalAmountImage from '../Assets/amount.jpeg';
import foodImage from '../Assets/hh.jpg'; // Update the import path

const Dashboard = () => {
  const [totalOrders, setTotalOrders] = useState(0);
  const navigate = useNavigate();
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    // Fetch total orders for today
    axios.get('/api/orders/today')
      .then(response => {
        setTotalOrders(response.data.total);
      })
      .catch(error => console.error('Error fetching total orders:', error));

    // Trigger animation
    setAnimationClass('animate');
  }, []);

  return (
    <div className={`dashboard ${animationClass}`}>
      <div className="dashboard-section">
        <h2 className='heading1'>Dashboard</h2>
        <div className="image-container">
          <div className="image-item" onClick={() => navigate('/orders')}>
            <img src={foodImage} alt="Orders" />
            <p>View Orders</p>
            <span className="overlay-text">Here you can see the Orders got placed by customers by just clicking the image. </span>
          </div>
          <div className="image-item">
            <img src={totalAmountImage} alt="Total Amount" />
            <p>Total Orders Today: {totalOrders}</p>
            <span className="overlay-text">Here you can see the Amount Details by just clicking the image.</span>
          </div>
        </div>
        <div className="inspiring-paragraph">
          <p>As the dedicated admin of this restaurant, you hold the reins to excellence. Your decisions and management shape the experience of every customer who walks through your doors. Embrace the power to lead, innovate, and ensure every service is impeccable. Your commitment is the heartbeat of your success, and your leadership transforms ordinary moments into extraordinary experiences. Welcome to a role where your vision and passion make all the difference.</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
