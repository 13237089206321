import React, { useState, useEffect, useContext } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import img1 from '../Assets/pot.jpg';
import img2 from '../Assets/namaste.png';
import img3 from '../Assets/house.jpg';
import logo from '../Assets/manidweep.png';

import AboutUs from '../Pages/AboutUs';
import OurMenu from '../Pages/OurMenu';
import History from '../Pages/History';
import Profile from '../Pages/Profile';

import './Home.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
    const [userRole, setUserRole] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { setUser } = useContext(UserContext);
    const [isAboutUsClicked, setIsAboutUsClicked] = useState(true);
    const [isMenuClicked, setIsMenuClicked] = useState(false);
    const [isHistoryClicked, setIsHistoryClicked] = useState(false);
    const [showSignupPopup, setShowSignupPopup] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showLoginPassword, setShowLoginPassword] = useState(false);
    const [isProfileClicked, setIsProfileClicked] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSignupPopup(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    const handleAboutUsClick = () => {
        setIsAboutUsClicked(true);
        setIsMenuClicked(false);
        setIsHistoryClicked(false);
        setIsProfileClicked(false);
    };

    const handleMenuClick = () => {
        setIsMenuClicked(true);
        setIsAboutUsClicked(false);
        setIsHistoryClicked(false);
        setIsProfileClicked(false);
    };

    const handleHistoryClick = () => {
        setIsHistoryClicked(true);
        setIsMenuClicked(false);
        setIsAboutUsClicked(false);
        setIsProfileClicked(false);
    };

    const handleProfileClick = () => {
        setIsProfileClicked(true);
        setIsMenuClicked(false);
        setIsAboutUsClicked(false);
        setIsHistoryClicked(false);
    };

    const handleCloseSignupPopup = () => {
        setShowSignupPopup(false);
    };

    const handleOpenLoginPopup = () => {
        setShowSignupPopup(false);
        setShowLoginPopup(true);
    };

    const handleCloseLoginPopup = () => {
        setShowLoginPopup(false);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const toggleShowLoginPassword = () => {
        setShowLoginPassword(!showLoginPassword);
    };

    const handleOpenForgotPasswordPopup = () => {
        setShowLoginPopup(false);
        navigate('/forgotpassword');
    };

    const handleForgotPasswordClick = () => {
        navigate('/forgotpassword');
    };

    const checkPasswordBreach = async (password) => {
        try {
            const response = await fetch(`https://api.pwnedpasswords.com/range/${password.substring(0, 5)}`);
            const data = await response.text();
            const hashSuffix = password.substring(5).toUpperCase();
            return data.includes(hashSuffix);
        } catch (error) {
            console.error('Error checking password breach:', error);
            return false;
        }
    };

    const SignupPage = () => {
        const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const toggleShowPassword = () => setShowPassword(!showPassword);
    const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
        const handleSignupSubmit = async (event) => {
            event.preventDefault();
            
            const username = event.target.username.value;
            const email = event.target.email.value;
            const phone_number = event.target.phone_number.value;
            const password = event.target.password.value;
            const confirmPassword = event.target.confirmPassword.value;
    
            if (password !== confirmPassword) {
                alert('Passwords do not match');
                return;
            }
    
            // Check for password breach
            if (await checkPasswordBreach(password)) {
                alert('Password has been breached. Please choose a different one.');
                return;
            }
    
            try {
                const response = await fetch('https://api.manidweep.functionparks.com/api/signup', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ username, email, phone_number, password }) 
                });
                const data = await response.json();
                if (response.ok) {
                    alert(data.message);
                    // Close the signup popup and open the login popup
                    setShowSignupPopup(false);
                    setShowLoginPopup(true);
                } else {
                    alert(data.message);
                }
            } catch (error) {
                console.error('Error:', error);
                alert('Failed to sign up');
            }
        };

        return (
            <div className="popup-signup">
                <div className="popup-inner">
                    <h2>Sign Up</h2>
                    <form onSubmit={handleSignupSubmit}>
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input type="text" id="username" name="username" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" name="email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone_number">Phone Number</label>
                            <input type="text" id="phone_number" name="phone_number" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Create Password</label>
                            <div className="password-wrapper">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    required
                                />
                                <button type="button" onClick={toggleShowPassword} className='eye-button'>
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <div className="password-wrapper">
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    required
                                />
                                <button type="button" onClick={toggleShowConfirmPassword} className='eye-button'>
                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                    <div className="login-section">
                        <button onClick={() => { setShowSignupPopup(false); setShowLoginPopup(true); }}>Login</button>
                        <span>Already a user?</span>
                    </div>
                </div>
            </div>
        );
    };

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        const username = event.target.loginUsername.value;
        const password = event.target.loginPassword.value;

        try {
            const response = await fetch('https://api.manidweep.functionparks.com/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password })
            });
            const data = await response.json();

            if (response.ok) {
                localStorage.setItem('token', data.token); // Store JWT token
                alert(`${data.role === 'admin' ? 'Admin' : 'User'} login successful`);
                setIsLoggedIn(true); // Update the state to reflect the user is logged in
                setUserRole(data.role);
                setUser(username);
                if (data.role === 'admin') {
                    navigate('/admin'); // Redirect to Admin component
                } else {
                    setShowLoginPopup(false); // Close the login popup
                    navigate('/'); // Ensure the user UI is displayed
                }
            } else {
                alert(`Invalid ${username === 'admin' ? 'admin' : 'user'} username or password`);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to login');
        }
    };

    return (
        <div className='app'>
            {/* Navbar header */}
            <div>
                <nav className="header">
                    <ul className="start">
                        <li><img src={img1} alt="" className='image1' /></li>
                        <li className='image2'>
                            <img src={img2} alt="" className='namaste' />
                            <br />
                            <img src={logo} alt="" className='manidweep' />
                        </li>
                        <li><img src={img3} alt="" className='image1' /></li>
                    </ul>
                </nav>
            </div>

            {/* Nav2 */}
            <div className='container'>
                <nav>
                    <ul className='list'>
                        <li onClick={handleAboutUsClick} data-aos="fade-right" data-aos-duration="1000">About Us</li>
                        <li onClick={handleMenuClick} data-aos="zoom-in-down" data-aos-duration="2000">Our Menu</li>
                        <li onClick={handleHistoryClick} data-aos="fade-left" data-aos-duration="3000">History</li>
                        <li onClick={handleProfileClick} data-aos="fade-left" data-aos-duration="3000">Profile</li>
                    </ul>
                </nav>
            </div>

            {/* About */}
            {isAboutUsClicked && <AboutUs />}

            {/* Menu */}
            {isMenuClicked && <OurMenu />}

            {/* History */}
            {isHistoryClicked && <History />}

            {/* Profile */}
            {isProfileClicked && <Profile />}

            {/* Footer */}
            <div className="container-fluid last">
                <div className="row footer"></div>
            </div>

            {/* Sign Up Popup */}
            {showSignupPopup && (
                <>
                    <div className="popup-overlay" />
                    <SignupPage setShowSignupPopup={setShowSignupPopup} setShowLoginPopup={setShowLoginPopup} />
                </>
            )}

            {/* Login Popup */}
            {showLoginPopup && (
                <>
                    <div className="popup-overlay" />
                    <div className="popup-login">
                        <div className="popup-inner">
                            <h2>Login</h2>
                            <form onSubmit={handleLoginSubmit}>
                                <div className="form-group">
                                    <label htmlFor="loginUsername">Username</label>
                                    <input type="text" id="loginUsername" name="loginUsername" required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="loginPassword">Password</label>
                                    <div className="password-wrapper">
                                        <input
                                            type={showLoginPassword ? "text" : "password"}
                                            id="loginPassword"
                                            name="loginPassword"
                                            required
                                        />
                                        <button type="button" onClick={toggleShowLoginPassword} className="eye-button">
                                            {showLoginPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                </div>
                                <button type="submit">Submit</button>
                            </form>
                            <div className="login-section">
                            <button className="back-to-signup-button" onClick={() => { setShowLoginPopup(false); setShowSignupPopup(true); }}>
                                             Back to Signup
                                </button>
                                <a href="#" className="forgot-password-link" onClick={handleOpenForgotPasswordPopup}>
                                             Forgot Password?
                                </a>
                               

                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Home;

