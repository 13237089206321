import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function OrderDetails() {
  const { id } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`https://api.manidweep.functionparks.com/api/orders/${id}`);
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrderDetails();
  }, [id]);

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Order Details</h2>
      <p><strong>Order ID:</strong> {order.id}</p>
      <p><strong>Order Date & Time:</strong> {new Date(order.created_at).toLocaleString()}</p>
      <p><strong>Item Total:</strong> {order.item_total}</p>
      <p><strong>Delivery Charge:</strong> {order.delivery_charge}</p>
      <p><strong>Delivery Tip:</strong> {order.delivery_tip}</p>
      <p><strong>Total Amount:</strong> {order.total_amount}</p>
      <p><strong>Delivery Type:</strong> {order.delivery_type}</p>
      <h3>Address</h3>
      <p><strong>Street Address:</strong> {order.street_address}</p>
      <p><strong>House Number:</strong> {order.house_number}</p>
      <p><strong>City:</strong> {order.city}</p>
      <p><strong>State:</strong> {order.state}</p>
      <p><strong>Zip Code:</strong> {order.zip_code}</p>
      <p><strong>Name:</strong> {order.name}</p>
      <p><strong>Phone Number:</strong> {order.phone_number}</p>
    </div>
  );
}

export default OrderDetails;
