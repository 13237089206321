import React, { useState } from 'react';

import img2 from '../Assets/namaste.png';
import img4 from '../Assets/muggu.png';

const History = () => {
  return (
    <div>
          {/* welcome */}
          <div className=' container-fluid hide0'>
    <div className="row">
    <div className='col-lg-1 col-1 '>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    </div>
    
    <div className='col-lg-10 col-10'>
    <div className='our'>
    <div className='heading' data-aos="zoom-in" data-aos-duration="1000" >
     <h2>Welcome to Manidweep </h2>
    </div>
    <div className='history' data-aos="zoom-in" data-aos-duration="1500">
    <h3>   history </h3><br/>
    </div>
    <div className='namaste1' data-aos="zoom-in" data-aos-duration="2000">
    <img src={img2} alt="" />
    </div>
     
    </div>
    </div>
    <div className='col-lg-1 col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />

    </div>

    </div>
      
        </div>
        {/* 1 */}
        
        <div className=' container-fluid  hide0'>
    <div className="row">
    <div className='col-lg-1 col-1 '>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />


    </div>
    
    <div className='col-lg-10 col-10'>
    <div className='our'>
    <div className='heading' data-aos="fade-right" data-aos-duration="2000">
     <h3>Embrace the Divine Taste of Tradition </h3>
     </div>
     <div className='text'  data-aos="zoom-in" data-aos-duration="3000"> 
     <h5>
     At Manidweep, we believe in nourishing the body and soul with every meal. Our culinary philosophy is deeply rooted in tradition and spirituality, offering you a unique dining experience that goes beyond taste. 
     </h5>
     </div>
    </div>
    </div>
    <div className='col-lg-1 col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />


    </div>

    </div>
      
        </div>

        {/* 2 */}
        <div className=' container-fluid hide0'>
    <div className="row">
    <div className='col-lg-1 col-1 '>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />


    </div>
    
    <div className='col-lg-10 col-10'>
    <div className='our'>
    {/* step1 */}
    <div className='heading2' data-aos="zoom-in" data-aos-duration="1000">
     <h3>Why Choose Manidweep?  </h3>
     </div>
     <div className='heading2' data-aos="zoom-in" data-aos-duration="2000">
     <h3>1. Traditional Cooking Methods:</h3>
     </div>
     <div className='text'  data-aos="zoom-in" data-aos-duration="3000"> 
     <h5>
     At Manidweep, we believe in nourishing the body and soul with every meal. Our culinary philosophy is deeply rooted in tradition and spirituality, offering you a unique dining experience that goes beyond taste. 
     </h5>
     </div>
     {/* step2 */}
     <div className='heading2' data-aos="zoom-in" data-aos-duration="2000">
     <h3>2. Spiritual Preparation:</h3>
     </div>
     <div className='text'  data-aos="zoom-in" data-aos-duration="3000"> 
     <h5>
     Every dish at Manidweep is prepared with a sense of devotion. Our cooking process involves prayer, and we follow strict guidelines to ensure our food is free from onions and garlic, making it suitable for those who observe specific dietary restrictions. Before delivery, each meal is offered to the divine, infusing it with blessings and positive energy.
     </h5>
     </div>
    </div>
    </div>
    <div className='col-lg-1 col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />



    </div>

    </div>
      
        </div>

        {/* 3 */}
        <div className=' container-fluid hide0'>
    <div className="row">
    <div className='col-lg-1 col-1 '>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />

    
    </div>
    
    <div className='col-lg-10 col-10'>
    <div className='our'>
    {/* step3 */}
    
     <div className='heading2'  data-aos="zoom-in" data-aos-duration="2000">
     <h3>3. Perfect for Festive Fasting:</h3>
     </div>
     <div className='text'  data-aos="zoom-in" data-aos-duration="3000"> 
     <h5>
     Our menu is specially curated to cater to those who are fasting during festivals. We understand the importance of purity and simplicity in such times, and our dishes are crafted to support your spiritual journey.
     </h5>
     </div>
     {/* step4 */}
     <div className='heading2'  data-aos="zoom-in" data-aos-duration="2000">
     <h3>4. Organic and Sustainable:</h3>
     </div>
     <div className='text'  data-aos="zoom-in" data-aos-duration="3000"> 
     <h5>
     We prioritize your health and the environment. Our packaging is 100% organic, ensuring that our commitment to sustainability extends beyond the kitchen. By choosing Manidweep, you are not only indulging in wholesome food but also contributing to a greener planet.
     </h5>
     </div>
    </div>
    </div>
    <div className='col-lg-1 col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    

    </div>

    </div>
      
        </div>

        {/* 4 */}
        <div className=' container-fluid hide0'>
    <div className="row">
    <div className='col-lg-1 col-1 '>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />


    </div>
    
    <div className='col-lg-10 col-10'>
    <div className='our'>
    <div className='heading' data-aos="zoom-in" data-aos-duration="1000">
     <h3>Our Offerings </h3>
     </div>
     <div className='text'> 
     <h5 data-aos="fade-right" data-aos-duration="1200"><span>1.Daily Meals: </span> Wholesome and delicious meals prepared with love and devotion</h5>
     <h5 data-aos="zoom-in" data-aos-duration="2000"><span>2.Festival Specials:</span> Special menus for festive fasting and celebrations. </h5>
     <h5 data-aos="fade-left" data-aos-duration="3000"><span>3.Custom Orders:</span> Tailored dishes to meet your specific dietary needs and preferences.</h5> 
     
     </div>
    </div>
    </div>
    <div className='col-lg-1 col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />

    <img src={img4} alt="" className='muggu text-hdn' />

    </div>

    </div>
      
        </div>

         {/* 5 */}
         <div className=' container-fluid hide0'>
    <div className="row">
    <div className='col-lg-1 col-1 '>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />


    </div>
    
    <div className='col-lg-10 col-10'>
    <div className='our'>
    <div className='heading'  data-aos="fade-right" data-aos-duration="2000">
     <h3>Experience the Manidweep Difference </h3>
     </div>
     <div className='text' data-aos="zoom-in" data-aos-duration="3000"> 
     <h5 >Join us at Manidweep, where every meal is a celebration of health, tradition, and spirituality. Allow us to bring the divine taste of ancient practices to your table, ensuring that each bite is filled with purity and devotion. </h5>
    <h5>Visit us and explore our menu and place your order today! </h5>
     </div>
    </div>
    </div>
    <div className='col-lg-1 col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />




    </div>

    </div>
      
        </div>
    </div>
  )
}

export default History