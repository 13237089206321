import React from 'react';
import './menu.css'; // Import the CSS file
import sravapindi from '../Assets/srava pindi.png'; 
import sabudana from '../Assets/sabudhana punugulu.png'; 
import polelu from '../Assets/polelu.jpg'; 
import kudumulu from '../Assets/kudumulu.png'; 
import kathambam from '../Assets/kathambam.png'; 
import bellam from '../Assets/bellam paisham.png'; 

const foodItems = [
  {
    id: 1,
    name: 'Srava Pindi',
    description: 'Classic Margherita with fresh basil, mozzarella, and tomatoes.',
    price: 300,
    imageUrl: sravapindi
  },
  {
    id: 2,
    name: 'Sabudhana Punugulu',
    description: 'Spaghetti with creamy carbonara sauce, pancetta, and parmesan.',
    price: 80,
    imageUrl: sabudana
  },
  {
    id: 3,
    name: 'Polelu',
    description: 'Crisp romaine lettuce with Caesar dressing, croutons, and parmesan.',
    price: 160,
    imageUrl: polelu
  },
  {
    id: 4,
    name: 'Kudumulu',
    description: 'Delicious Italian dessert made with coffee, mascarpone, and cocoa.',
    price: 300,
    imageUrl: kudumulu
  },
  {
    id: 5,
    name: 'Kathambam',
    description: 'Spicy pepperoni slices on a bed of melted cheese and tomato sauce.',
    price: 250,
    imageUrl: kathambam
  },
  {
    id: 6,
    name: 'Bellam Paisham',
    description: 'Pasta tossed in a rich and creamy Alfredo sauce with garlic and parmesan.',
    price: 300,
    imageUrl: bellam
  }

];

const Menu = () => {
  return (
    <div className="menu-wrapper">
      <h2 className="menu-heading1">Menu</h2>
      {foodItems.length > 0 ? (
        <ul className="menu-list">
          {foodItems.map((item) => (
            <li key={item.id} className="menu-item">
              <img src={item.imageUrl} alt={item.name} />
              <div className="menu-item-content">
                <h3>{item.name}</h3>
                <p>{item.description}</p>
                <p><strong>₹{item.price.toFixed(2)}</strong></p>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No items available</p>
      )}
    </div>
  );
  
};

export default Menu;
