import React, { useState, useEffect } from 'react';
import axios from 'axios';
import img4 from '../Assets/muggu.png';
import { FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './profile.css';

const Profile = ({ setShowSignupPopup }) => {
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchUserDetails = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No token found');
      return;
    }
    try {
      const response = await axios.get('https://api.manidweep.functionparks.com/api/user-details', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data);
    } catch (err) {
      setError('Failed to fetch user details');
      console.error(err);
    }
  };

  const fetchUserOrders = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No token found');
      return;
    }
    try {
      const response = await axios.get('https://api.manidweep.functionparks.com/api/user-orders', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrders(response.data);
    } catch (err) {
      setError('Failed to fetch orders');
      console.error(err);
    }
  };

  const handletheLogout = () => {
    // Perform any necessary logout actions here
    // For example, clear authentication tokens
    localStorage.removeItem('authToken'); // Or use your specific token storage mechanism

    // Navigate to the home page
    navigate('/');
  };


  useEffect(() => {
    fetchUserDetails();
    fetchUserOrders();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!user || orders.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className='container-fluid hide0'>
        <div className="row">
          <div className='col-lg-1 col-1'>
            <img src={img4} alt="" className='muggu' />
            <img src={img4} alt="" className='muggu text-hdn' />
            <img src={img4} alt="" className='muggu text-hdn' />
          </div>
          <div className='col-lg-10 col-10'>
            <div className='User_Details'>
              <h2>User Details</h2>
              <p><strong>Username :</strong> {user.username}</p>
              <p><strong>Email :</strong> {user.email}</p>
              <p><strong>Phone Number :</strong> {user.phone_number}</p>
              <button onClick={handletheLogout} className='logout-btn'>
                <FaSignOutAlt /> Logout
              </button>
            </div>
          </div>
          <div className='col-lg-1 col-1'>
            <img src={img4} alt="" className='muggu' />
            <img src={img4} alt="" className='muggu text-hdn' />
            <img src={img4} alt="" className='muggu text-hdn' />
          </div>
        </div>
      </div>

      <div className='container-fluid hide0'>
        <div className="row">
          <div className='col-lg-1 col-1'>
            <img src={img4} alt="" className='muggu' />
            <img src={img4} alt="" className='muggu text-hdn' />
            <img src={img4} alt="" className='muggu text-hdn' />
          </div>
          <div className='col-lg-10 col-10'>
            <div className='table-head'>
              <h2>Your Orders</h2>
              <div className='table-wrapper'>
                {orders.length === 0 ? (
                  <p>No orders found.</p>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Dishes</th>
                        <th>Quantity</th>
                        <th>Delivery Charge</th>
                        <th>Delivery Tip</th>
                        <th>Total Amount</th>
                        <th>Address</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map(order => (
                        <tr key={order.id}>
                          <td>{order.dishes}</td>
                          <td>{order.order_quantity}</td>
                          <td>{order.delivery_charge}</td>
                          <td>{order.delivery_tip}</td>
                          <td>{order.total_amount}</td>
                          <td>{order.address}</td>
                          <td>{new Date(order.created_at).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <div className='col-lg-1 col-1'>
            <img src={img4} alt="" className='muggu' />
            <img src={img4} alt="" className='muggu text-hdn' />
            <img src={img4} alt="" className='muggu text-hdn' />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
