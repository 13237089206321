import React, { useState,useContext } from 'react';
import { UserContext } from '../context/UserContext';
import img4 from '../Assets/muggu.png';
import img5 from '../Assets/polelu.png';
import img6 from '../Assets/bellam.png';
import img7 from '../Assets/kathambam.png';
import img8 from '../Assets/kudumulu.png';
import img9 from '../Assets/sarvapindi.png';
import img10 from '../Assets/punugulu.png';

const dishes = [
  { id: 1, name: 'Bellam Pasham', price: 300, img: img6 , description:'Bellam Pasham, a traditional sweet dish, is a cherished dessert from ancient Indian cuisine. Rooted in heritage and rich in flavor, this delicacy is a timeless favorite, perfect for celebrations and special occasions. Ingredients- jaggery,rice flour,water,dry fruits, ghee'},
  { id: 2, name: 'Kudumulu', price: 300, img: img8, description:'Kudumulu, also known as Undrallu, is a traditional South Indian delicacy often prepared during festivals and auspicious occasions. This steamed rice flour dumpling is not only delicious but also a testament to the simplicity and elegance of ancient Indian cuisine. Ingredients-Rice flour,Water,Dal,oil,Cumin & Jeera,Salt, Grated fresh coconut ' },
  { id: 3, name: 'Polelu-2Qts', price: 160, img: img5, description:'Polelu, also known as Bobbatlu or Puran Poli, is a traditional sweet flatbread from South India. Made with a filling of chana dal and jaggery, encased in a thin layer of dough, this delicacy is lightly roasted to perfection ' },
  { id: 4, name: 'Kathambam', price: 250, img: img7,description:' Kathambam is a traditional South Indian mixed rice dish, combining an array of lentils, vegetables, and spices. Known for its wholesome and nutritious qualities, Kathambam offers a delightful blend of flavors and textures. This dish is perfect for a balanced meal, celebrating the rich culinary heritage of South India.' },
  { id: 5, name: 'Sarvapindi', price: 300, img: img9 , description: 'Sarvapindi is a traditional savory pancake from Telangana, made with rice flour, chana dal, sesame seeds, and spices. It has a unique texture, being both crunchy and chewy. Enjoy this age-old delicacy  paired with yogurt or chutney.'},
  { id: 6, name: 'Sabudhana Punugulu', price: 80, img: img10, description:'Sabudhana Punugulu is a crispy and flavorful snack, made by soaking sago pearls (sabudhana) in curd for a unique tangy twist. Combined with spices, these fritters are fried to golden perfection in iron pans. Prepared without onions and garlic. Enjoy this delightful treat with a side of chutney.' },
];

const OurMenu = () => {
  const { user } = useContext(UserContext);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedDish, setSelectedDish] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [isAddressPopupVisible, setIsAddressPopupVisible] = useState(false);
  const [isAddAddressPopupVisible, setIsAddAddressPopupVisible] = useState(false);

  const [isAddItemPopupVisible, setIsAddItemPopupVisible] = useState(false);
// 

const [addresses, setAddresses] = useState([]);
const [newAddress, setNewAddress] = useState({
  street: '',
  house: '',
  city: '',
  state: '',
  zip: '',
  name: '',
  phone: '',
});



const handleInputChange = (e) => {
  const { name, value } = e.target;
  setNewAddress({ ...newAddress, [name]: value });
};


const handleAddAddress = () => {
  if (
    newAddress.name &&
    isValidPhoneNumber(newAddress.phone) &&
    newAddress.street &&
    newAddress.house &&
    newAddress.city &&
    newAddress.state &&
    newAddress.zip
  ) {
    setAddresses([...addresses, newAddress]);
    setNewAddress({
      name: '',
      phone: '',
      street: '',
      house: '',
      city: '',
      state: '',
      zip: '',
    });
    setIsAddAddressPopupVisible(false);
    setIsAddressPopupVisible(true);
  } else {
    alert('Please fill out all fields correctly, including a valid phone number.');
  }
};
const handleEditAddress = (index) => {
  const addressToEdit = addresses[index];
  setNewAddress(addressToEdit);
  setIsAddAddressPopupVisible(true);
  setIsAddressPopupVisible(false);
  setAddresses(addresses.filter((_, i) => i !== index));
};

const handleDeleteAddress = (index) => {
  setAddresses(addresses.filter((_, i) => i !== index));
};
const handleCancel = () => {
  setIsAddAddressPopupVisible(false);
  setIsAddressPopupVisible(true);
};
const isValidPhoneNumber = (phone) => {
  const phoneRegex = /^[0-9]{10}$/; // Adjust this regex as needed for your validation rules
  return phoneRegex.test(phone);
};

const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
const [selectedAddress, setSelectedAddress] = useState(null);
const [savedAddress, setSavedAddress] = useState(null);
const handleAddressSelection = (index) => {
  setSelectedAddressIndex(index);
  setSelectedAddress(addresses[index]); // Update the selected address
};

const handleSaveAddress = () => {
  if (selectedAddressIndex !== null) {
    setSavedAddress(addresses[selectedAddressIndex]);
    setIsAddressPopupVisible(false); // Hide the address popup
  }
};
// 
const [selectedDishes, setSelectedDishes] = useState([]);
const [selectedDishList, setSelectedDishList] = useState([]); // State to store selected dishes

const [initialDish, setInitialDish] = useState(null);
const [additionalDishes, setAdditionalDishes] = useState([]);

const handleButtonClick = (dish) => {
  setInitialDish(dish);
  setQuantity(1);
  setPrice(dish.price);
  setIsPopupVisible(true);
};

const closePopup = () => {
  setIsPopupVisible(false);
  setInitialDish(null);
  setAdditionalDishes([]); // Reset additional dishes
  setTip(0);
  setIsAddressPopupVisible(false);
};

const increment = () => {
  setQuantity((prevQuantity) => {
    const newQuantity = prevQuantity + 1;
    setPrice(newQuantity * (initialDish ? initialDish.price : 0));
    return newQuantity;
  });
};

const decrement = () => {
  setQuantity((prevQuantity) => {
    if (prevQuantity > 1) {
      const newQuantity = prevQuantity - 1;
      setPrice(newQuantity * (initialDish ? initialDish.price : 0));
      return newQuantity;
    }
    return prevQuantity;
  });
};

const getDeliveryCharge = () => {
  const totalPrice = getTotalPrice();
  return totalPrice > 200 ? 0 : 49;
};

const getTotalPrice = () => {
  const initialPrice = initialDish ? price : 0;
  const additionalPrice = additionalDishes.reduce((sum, dish) => sum + dish.price, 0);
  return initialPrice + additionalPrice;
};
const getItemList = () => {
  const itemList = [];
  if (initialDish) {
    itemList.push({ name: initialDish.name, price });
  }
  additionalDishes.forEach(dish => {
    itemList.push({ name: dish.name, price: dish.price });
  });
  return itemList;
};
const getTotalAmount = () => {
  return getTotalPrice() + getDeliveryCharge();
};

const [tip, setTip] = useState(0);
const [isTipBoxVisible, setIsTipBoxVisible] = useState(false);

const handleTipChange = (e) => {
  setTip(Number(e.target.value));
};

const handleAddTip = () => {
  setIsTipBoxVisible(false);
};

const totalAmountWithTip = getTotalAmount() + tip;

const handleAddAddressClick = () => {
  setIsAddressPopupVisible(false); // Optionally close the main popup if needed
  setIsAddAddressPopupVisible(true); // Open the address entry popup
};

// Handle dish addition and removal
const handleDishSelection = (dishId) => {
  if (selectedDishes.includes(dishId)) {
    setSelectedDishes(selectedDishes.filter(id => id !== dishId));
  } else {
    setSelectedDishes([...selectedDishes, dishId]);
  }
};

const handleAddDishes = () => {
  const selected = dishes.filter(dish => selectedDishes.includes(dish.id));
  setAdditionalDishes(selected); // Store additional dishes
  setSelectedDishes([]); // Clear the selection
  setIsAddItemPopupVisible(false);
};

const handleRemoveDish = (dishId) => {
  setAdditionalDishes(additionalDishes.filter(dish => dish.id !== dishId));
};

const handleConfirmOrder = async () => {
  try {
      // Fetch user_id based on the stored username
      const userResponse = await fetch('https://api.manidweep.functionparks.com/api/getUserId', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ username: user }) // Assuming 'user' contains the username
      });

      if (!userResponse.ok) {
          const errorData = await userResponse.json();
          throw new Error(errorData.message || 'Failed to fetch user ID');
      }

      const userData = await userResponse.json();
      const userId = userData.userId;
      console.log(userData);
      console.log(userId);
      // Prepare order details with userId
      const itemList = getItemList();
      const dishIds = itemList.map(item => item.name);
      const orderDetails = {
          user_id: userId, // Pass the retrieved userId
          dish_id: dishIds,
          address: savedAddress,
          delivery_charge: getDeliveryCharge(),
          delivery_tip: tip,
          total_amount: totalAmountWithTip
      };

      // Sending order details to backend
      const orderResponse = await fetch('https://api.manidweep.functionparks.com/api/place-order', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(orderDetails)
      });

      if (!orderResponse.ok) {
          const errorData = await orderResponse.json();
          throw new Error(errorData.message || 'Failed to place order');
      }

      const orderData = await orderResponse.json();
      console.log('Order placed successfully:', orderData);
      alert('Order placed successfully');
      closePopup();
  } catch (error) {
      console.error('Error placing order:', error);
      alert('Failed to place order');
  }
};

  // -----------------------------------

  return (
    <div>
      <div className='container-fluid hide0'>
        <div className="row">
          <div className='col-lg-1 col-1 '>
            <img src={img4} alt="" className='muggu' />
            <img src={img4} alt="" className='muggu text-hdn' />
            <img src={img4} alt="" className='muggu text-hdn' />
            
          </div>
          <div className='col-lg-10 col-10'>
            <div className='our'>
              <div className='heading' data-aos="fade-right" data-aos-duration="2000">
                <h3>Our Menu</h3>
              </div>
              <div className='text' data-aos="zoom-in" data-aos-duration="3000">
                <h5>
                  Our menu is a culinary voyage through the annals of time, showcasing the diversity and richness of ancient cuisines. Each dish is prepared with care and attention to detail, ensuring an authentic taste experience that transports you back to the times of its origin.
                </h5>
              </div>
            </div>
          </div>
          <div className='col-lg-1 col-1'>
            <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />

          </div>
        </div>
      </div>
      {/* dishes */}
      {dishes.map((dish, index) => (
        <div key={dish.id} className='container-fluid hide0'>
          <div className="row">
            <div className='col-lg-1 col-1'>
              <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />

            </div>
            <div className='col-lg-10 col-10'>
              <div className='total'>
                <div className='row'>
                  <div className={`col-lg-6 col-12 ${index % 2 === 0 ? '' : 'order-lg-2'}`}>
                    <div className="card" data-aos="zoom-in" data-aos-duration="2000">
                      <div className="container-image">
                        <img src={dish.img} alt="" className='image-circle' />
                      </div>
                      <div className="content">
                      <div className="product-image one">
                      <div className="box-image  text-hdn">
                            <img src={dish.img} alt="" className='image-circle' />
                          </div>
                      </div>
                    
                        <div className="detail">
                          <span>{dish.name}</span>
                          <p>₹{dish.price}</p>
                          <button className="button" onClick={() => handleButtonClick(dish)}>
                            Add me
                            <span></span><span></span><span></span><span></span>
                          </button>
                        </div>
                        <div className="product-image text-hdn1">
                          <div className="box-image text-hdn">
                            <img src={dish.img} alt="" className='image-circle' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`col-lg-6 col-12 ${index % 2 === 0 ? '' : 'order-lg-1'}`}>
                    <div className={`card${dish.id} para${index % 2 === 0 ? '' : '2'}`} data-aos={index % 2 === 0 ? "fade-right" : "fade-left"} data-aos-duration="2000">
                      <h5 data-aos="zoom-in" data-aos-duration="3000">{dish.description}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-1 col-1'>
              <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />

            </div>
          </div>
        </div>
      ))}

      {/*  add me popup*/}
      
    {isPopupVisible && (
  <div className="popup">
    <div className="popup-content">
      <div className='container-fluid'>
        <div className="row">
          <div className='col-lg-6 col-6 image-container'>
          
            {initialDish && (
              <div>
                <div className="green-box">
                  <span className="green-dot"></span>
                </div>
                <p>{initialDish.name}</p>
                <p>[250M]</p>
                <p>₹{price}</p>
              </div>
            )}
          </div>
          <div className='col-lg-6 col-6'>
            {initialDish && (
              <img src={initialDish.img} alt="" className='popup_img' />
            )}
            <button className="counter-button">
              <span className="symbol" onClick={decrement}>-</span>
              <span className="number">{quantity}</span>
              <span className="symbol" onClick={increment}>+</span>
            </button>
          </div>
        </div>
        <div className='add-item'>
          <div className="add-item-container" onClick={() => setIsAddItemPopupVisible(true)}>
            <div className="add-item-plus-box">+</div>
            <span>Add Item</span>
          </div>
          {additionalDishes.length > 0 && (
            <div className="selected-dishes">
              <h6>Selected Dishes:</h6>
              <div className="dish-list">
                {additionalDishes.map(dish => (
                  <div key={dish.id} className="dish-item">
                    <img src={dish.img} alt={dish.name} />
                    <p>{dish.name}</p>
                    <p>₹{dish.price}</p>
                    <span className="remove-icon" onClick={() => handleRemoveDish(dish.id)}>X</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className='bill-details'>
          <h5>Bill Details</h5>
          <div className='mincard'>
          {getItemList().map((item, index) => (
              <div className="item" key={index}>
                <p>{item.name}:</p>
                <p>₹{item.price}</p>
              </div>
            ))}
            <div className="item">
              <p>Item Total:</p>
              <p>₹{getTotalPrice()}</p>
            </div>
            <div className="item">
              <p>Delivery Charge:</p>
              <p>₹{getDeliveryCharge()}</p>
            </div>
            <p>Free delivery with Manidweep One is<br/> 
            applicable on orders above ₹200</p>
            <div className="item">
              {isTipBoxVisible ? (
                <div>
                  <input type="number" value={tip} onChange={handleTipChange} placeholder="Enter tip amount" />
                  <span className='tip' onClick={handleAddTip}>Enter</span>
                </div>
              ) : (
                <div className="item">
                  <p>Delivery Tip: </p>
                  <span className='tip' onClick={() => setIsTipBoxVisible(true)}>
                    {tip > 0 ? `₹${tip}` :  ' Add Tip'}
                  </span>
                </div>
              )}
            </div>
            <div className="item total">
              <p><strong>Total Amount:</strong></p>
              <p><strong>₹{totalAmountWithTip}</strong></p>
            </div>
            <div className="item ">
              <p>Cash on Delivery only</p>
            </div>
          </div>
        </div>
        <div className='add-address'>
          <h6>Selected Address:</h6>
          {savedAddress ? (
            <div className='selected-address'>
              <p>{savedAddress.street}, {savedAddress.house}, {savedAddress.city}, {savedAddress.state}, {savedAddress.zip} , {savedAddress.name}, {savedAddress.phone}</p>
            </div>
          ) : (
            <p>No address selected.</p>
          )}
          <button className='address' onClick={() => setIsAddressPopupVisible(true)}>Add Address</button>
        </div>
        <div className='review'>
          <h6>Review Your order and address details to avoid cancellations </h6>
          <div className='review-card'>
            <span>Note: </span>
            <p>If You cancel Within 120 seconds of placing Your order, a 100% refund Will Be issued. No refund for cancellations after 120seconds.</p>
            <p>Avoid cancellation as it leads to food Wastage.</p>
          </div>
        </div>
        <div className='orderbtn'>
        <button  onClick={handleConfirmOrder}>Place Order</button>
        <button onClick={closePopup}>Close</button>
        </div>
        
      </div>
    </div>
  </div>
    )}
      {/* Add Item Popup */}
      {isAddItemPopupVisible && (
        <div className="add-item-popup">
          <div className="add-item-popup-content">
            <h4>Select a Dish</h4>
            <div className="dish-list">
            {dishes.map(dish => (
          <div key={dish.id} className="dish-item">
            
            <img src={dish.img} alt={dish.name} />
            <p>{dish.name}</p>
            <p>₹{dish.price}</p>
            <input
              type="checkbox"
              checked={selectedDishes.includes(dish.id)}
              onChange={() => handleDishSelection(dish.id)}
            />
          </div>
        ))}
            </div>
            <div className='dish-list-btn'>
            <button onClick={() => setIsAddItemPopupVisible(false)}>Close</button>
            <button onClick={handleAddDishes}>Add</button>
            </div>
            
          </div>
        </div>
      )}
      {/* --address popup */}

      {isAddressPopupVisible && (
        <div className="address-popup">
          <div className="address-popup-content">
            <h5>Choose a delivery address</h5>
            {addresses.map((address, index) => (
        <div key={index} className="address-item">
          <input 
            type="checkbox" 
            name="address" 
            id={`address-checkbox-${index}`} 
            value={index}
            checked={selectedAddressIndex === index}
            onChange={() => handleAddressSelection(index)}
          />
          <label htmlFor={`address-checkbox-${index}`}>
            <p>{address.street}, {address.house}, {address.city}, {address.state}, {address.zip} , {address.name}, {address.phone}</p>
          </label>
          <button onClick={() => handleEditAddress(index)}>Edit</button>
          <button onClick={() => handleDeleteAddress(index)}>Delete</button>
        </div>
      ))}
            <div className="add-address-container"  onClick={handleAddAddressClick}>
            <div className="plus-box">+</div>
            <span>Add New Address</span>
            </div>
            <div className='add-address-btn'>
            <button onClick={handleSaveAddress}>Save</button>
            <button onClick={() => setIsAddressPopupVisible(false)}>Close</button>
            </div>
            
          </div>
        </div>
      )}
      {/* add new address popup */}
      
      {isAddAddressPopupVisible && (
        <div className="add-address-popup">
          <div className="add-address-popup-content">
            <h5>Enter New Address</h5>
            <input
              type="text"
              name="street"
              placeholder="Street Address"
              value={newAddress.street}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="house"
              placeholder="House No/Flat No/Block No"
              value={newAddress.house}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={newAddress.city}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              value={newAddress.state}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="zip"
              placeholder="Zip Code"
              value={newAddress.zip}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={newAddress.name}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone Number"
              value={newAddress.phone}
              onChange={handleInputChange}
            />
            <div className='new-address-btn'>
            <button onClick={handleAddAddress}>Add</button>
            <button onClick={handleCancel}>Cancel</button>
            </div>
            
          </div>
        </div>
      )}
    </div>
  );
};

export default OurMenu;
