import React, { useState }  from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import carousel1 from '../Assets/carousel1.png';
import carousel2 from '../Assets/carousel2.png';
import carousel3 from '../Assets/carousel3.png';
import carousel4 from '../Assets/carousel4.png';
import carousel5 from '../Assets/carousel5.png';
import carousel6 from '../Assets/carousel6.png';

import img4 from '../Assets/muggu.png';


const AboutUs = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        cssEase: "linear",
        arrows: false 
      
    };
  return (
    <div>
        <div className=' container-fluid hide0' >
    <div className="row">
    <div className='col-lg-1  col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />

    </div>
    <div className='col-lg-5 col-10'>     
    <Slider {...settings}>
                <div data-aos="flip-right" data-aos-duration="2500">
                    <img src={carousel1} alt="Image 1" className='food'/>
                </div>
                <div data-aos="flip-right" data-aos-duration="2500">
                    <img src={carousel2} alt="Image 2" className='food'/>
                </div>
                <div data-aos="flip-right" data-aos-duration="2500">
                    <img src={carousel3} alt="Image 3" className='food'/>
                </div>
                <div data-aos="flip-right" data-aos-duration="2500">
                    <img src={carousel4} alt="Image 4" className='food'/>
                </div>
                <div data-aos="flip-right" data-aos-duration="2500">
                    <img src={carousel5} alt="Image 5" className='food'/>
                </div>
                <div data-aos="flip-right" data-aos-duration="2500">
                    <img src={carousel6} alt="Image 6" className='food'/>
                </div>
    </Slider>
    <div className='text-hdn'>
    <div className='hide00'>
    <div className='heading ' data-aos="fade-left" data-aos-duration="2000">
    <h3>About Manidweep </h3>
    </div>
    <div className='text'   data-aos="zoom-in" data-aos-duration="3000">
    <h5>Welcome to Manidweep, where the flavors of antiquity come alive. Nestled in the heart of Secunderabad, Manidweep is a unique culinary haven that transports you back to the glorious days of ancient civilizations. Our mission is to revive and celebrate the culinary arts of the past, offering a dining experience that is as educational as it is delicious. </h5>
    </div>
    </div>
    </div>
    </div>
    <div className='col-lg-5 text-hdn1 '> 
    <div className=''>
    <div className='hide00'>
    <div className='heading ' data-aos="fade-left" data-aos-duration="2000">
    <h3>About Manidweep </h3>
    </div>
    <div className='text'   data-aos="zoom-in" data-aos-duration="3000">
    <h5>Welcome to Manidweep, where the flavors of antiquity come alive. Nestled in the heart of Secunderabad, Manidweep is a unique culinary haven that transports you back to the glorious days of ancient civilizations. Our mission is to revive and celebrate the culinary arts of the past, offering a dining experience that is as educational as it is delicious. </h5>
    </div>
    </div> 
    </div>
   
    
    </div>
    
    <div className='col-lg-1 col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />

    </div>

    </div>
      
        </div>
   
    {/* ------ */}
    <div className=' container-fluid hide0'>
    <div className="row">
    <div className='col-lg-1 col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    

    </div>
    <div className='col-lg-10 col-10'>
    
    <div className='heading text0' data-aos="fade-right" data-aos-duration="2000">
      <h3>Our Story </h3>     
    </div>
    <div className='text'   data-aos="zoom-in" data-aos-duration="3000">
    <h5>The journey of Manidweep began with a deep-rooted passion for history and gastronomy. Our founders, inspired by the rich tapestries of ancient cultures, embarked on a quest to rediscover the culinary secrets of yesteryears. From the opulent feasts of ancient monarchs to the humble meals of agrarian societies, every dish at Manidweep is a meticulously researched and faithfully recreated piece of history. </h5>    
    </div>
     
    </div>
    <div className='col-lg-1 col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />


    </div>

    </div>
      
    </div>
    {/* -------- */}
    <div className=' container-fluid hide0'>
    <div className="row">
    <div className='col-lg-1 col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />
    

    </div>
    <div className='col-lg-10 col-10'>
    
    <div className='heading  text0' data-aos="fade-left" data-aos-duration="2000">
      <h3>Our Philosophy </h3>
    </div>
    <div className='text'  data-aos="zoom-in" data-aos-duration="3000">
    <h5 >At Manidweep, we are committed to authenticity and excellence. Our chefs are culinary historians, delving into ancient manuscripts, inscriptions, and traditional oral histories to unearth long-forgotten recipes. We employ traditional cooking techniques, such as slow roasting in clay ovens, fermenting, and stone grinding, to preserve the integrity and authenticity of each dish. By sourcing the finest ingredients from local farmers and heritage suppliers, we ensure that every meal is a genuine reflection of its historical origins. </h5>    

    </div>
     
    </div>
    <div className='col-lg-1 col-1'>
    <img src={img4} alt="" className='muggu' />
    <img src={img4} alt="" className='muggu text-hdn' />
    <img src={img4} alt="" className='muggu text-hdn' />

    </div>

    </div>
      
    </div>
    </div>
  )
}

export default AboutUs