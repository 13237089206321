import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import ForgotPassword from './components/forgotpassword';
import ResetPasswordPage from './components/resetpasswordpage';
import Admin from './Admin/Admin';



const App = () => {
  
  return (
    <Router>
      <Routes>       
        <Route path="/" element={<Home />} />   
        <Route path="/forgotpassword" element={<ForgotPassword />} /> 
        <Route path="/Admin/*" element={<Admin/>}></Route>         
      </Routes>
      
    
    </Router>
  );
};

export default App;
