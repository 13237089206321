import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import './sidebar.css'; // Ensure this file exists or remove if not needed

function Sidebar({ isSidebarOpen, toggleSidebar, toggleMobileMenu, isMobileMenuOpen }) {
  const navigate = useNavigate(); // Hook for navigation

  const handleLogout = () => {
    // Perform any necessary logout actions here
    // For example, clear authentication tokens
    localStorage.removeItem('authToken'); // Or use your specific token storage mechanism

    // Navigate to the home page
    navigate('/');
  };

  return (
    <div className={`sidebar ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <button onClick={toggleSidebar} className="sidebar-toggle">
        {isSidebarOpen ? '◀' : '▶'} {/* Unicode arrows for expanding and collapsing */}
      </button>
      <button onClick={toggleMobileMenu} className="mobile-menu-toggle">
        ☰
      </button>
      <div className={`sidebar-content ${isSidebarOpen ? 'content-open' : 'content-closed'}`}>
        <h1 className={`sidebar-title ${isSidebarOpen ? 'title-open' : 'title-closed'}`}>MANIDWEEP</h1>
        <ul className={`nav-links ${isSidebarOpen ? 'open' : 'closed'} ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
          <li>
            <Link to="/Admin/" className="nav-link" onClick={toggleMobileMenu}>
              <i className="icon">🏠</i>
              {isSidebarOpen && <span>Dashboard</span>}
            </Link>
          </li>
          <li>
            <Link to="/Admin/orders" className="nav-link" onClick={toggleMobileMenu}>
              <i className="icon">📦</i>
              {isSidebarOpen && <span>Orders</span>}
            </Link>
          </li>
          <li>
            <Link to="/Admin/menu" className="nav-link" onClick={toggleMobileMenu}>
              <i className="icon">🍴</i>
              {isSidebarOpen && <span>Menu</span>}
            </Link>
          </li>
          <li>
            <button onClick={handleLogout} className="nav-link">
              <FaSignOutAlt /> {isSidebarOpen && <span>Logout</span>}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
