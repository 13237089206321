import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [showResetForm, setShowResetForm] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    const handleForgotPassword = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://api.manidweep.functionparks.com/api/forgot-password', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email })
            });

            const data = await response.json();
            if (response.ok) {
                setShowResetForm(true); // Show the reset password form
                setMessage(data.message);
            } else {
                setMessage(data.message);
                setShowResetForm(false); // Hide the reset password form
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Failed to request password reset.');
        }
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        }

        try {
            const response = await fetch('https://api.manidweep.functionparks.com/api/reset-password', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, newPassword })
            });

            const data = await response.json();
            if (response.ok) {
                setMessage('Password reset successfully.');
                setEmail('');
                setNewPassword('');
                setConfirmPassword('');
                setShowResetForm(false); // Hide the reset password form
                // Redirect to home page
                navigate('/');
            } else {
                setMessage(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Failed to reset password.');
        }
    };

    return (
        <div className="forgot-password-page">
            <h2>Forgot Password</h2>
            {!showResetForm ? (
                <form onSubmit={handleForgotPassword}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Request Password Reset</button>
                </form>
            ) : (
                <div className="reset-password-form">
                    <h3>Reset Password</h3>
                    <form onSubmit={handleResetPassword}>
                        <div className="form-group">
                            <label htmlFor="newPassword">New Password</label>
                            <input
                                type="password"
                                id="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit">Reset Password</button>
                    </form>
                </div>
            )}
            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default ForgotPasswordPage;
