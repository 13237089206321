// import React from 'react'

// const Admin = () => {
 
//   return (
//     <div>Admin panel</div>
//   )
// }

// export default 

import React, {useState} from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Dashboard from './dashboard';
import OrderList from './orderlist';
import OrderDetails from './orderdetails';
import Menu from './menu';
import Sidebar from './sidebar';

const Admin = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
<div className={`App ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          toggleMobileMenu={toggleMobileMenu}
          isMobileMenuOpen={isMobileMenuOpen}
        />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="orders" element={<OrderList />} />
            <Route path="orders/:id" element={<OrderDetails />} />
            <Route path="menu" element={<Menu />} />
          </Routes>
        </div>
      </div>
  );
};

export default Admin;
